.react-chatbot-kit-chat-container {
  height: 90vh; /* Full height of the viewport */
  width: 100vw !important;  /* Full width of the viewport */
  position: fixed; /* Fixed position to cover the entire screen */
  top: 0;
  left: 0;
  overflow: hidden; /* Prevent scrolling within the container */
}

/* Additional styling to ensure inner components fit well */
.react-chatbot-kit-chat-inner-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.react-chatbot-kit-chat-message-container {
  flex-grow: 1; /* Allow message container to fill available space */
  overflow-y: auto; /* Enable scrolling for messages */
}

.react-chatbot-kit-chat-input-container {
  margin: 0; /* Remove margin */
}

.react-chatbot-kit-chat-container {
  height: 90vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.react-chatbot-kit-chat-inner-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.react-chatbot-kit-chat-message-container {
  flex-grow: 1; /* Take up all available space */
  overflow-y: auto; /* Scroll vertically if content overflows */
  margin: 0; /* Remove margins */
  width: 100%; /* Full width of the container */
}

.react-chatbot-kit-chat-header {
  /* Assuming you want to keep the header visible */
  padding: 10px; /* Optional: for better look */
}

.react-chatbot-kit-chat-input-container {
  padding: 10px; /* Optional: for better look */
}

.react-chatbot-kit-chat-container {
  height: 90vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.react-chatbot-kit-chat-inner-container {
  flex-grow: 1;  /* This will make it fill the space left by header and input container */
  width: 100%; /* Ensure it takes the full width */
  display: flex;
  flex-direction: column;
}

.react-chatbot-kit-chat-message-container {
  flex-grow: 1; /* Fill the remaining space */
  overflow-y: auto; /* Allow scrolling */
  width: 100%; /* Full width */
}

.react-chatbot-kit-chat-header, .react-chatbot-kit-chat-input-container {
  width: 100%; /* Full width */
  flex-shrink: 0; /* Prevent these components from shrinking */
}
.react-chatbot-kit-chat-container {
  height: 90vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0; /* Ensure no padding is affecting the inner width */
  margin: 0; /* Ensure no margin is affecting the inner width */
}

.react-chatbot-kit-chat-inner-container {
  flex-grow: 1;  /* This will make it fill the space left by header and input container */
  width: 100%; /* Ensure it takes the full width */
  display: flex;
  flex-direction: column;
  padding: 0; /* Remove padding to ensure width is not affected */
}

.react-chatbot-kit-chat-message-container {
  flex-grow: 1; /* Fill the remaining space */
  overflow-y: auto; /* Allow scrolling */
  width: 100%; /* Full width */
  padding: 0; /* Remove padding to ensure width is not affected */
}

.react-chatbot-kit-chat-header, .react-chatbot-kit-chat-input-container {
  width: 100%; /* Full width */
  flex-shrink: 0; /* Prevent these components from shrinking */
  padding: 0; /* Remove padding to ensure full width usage */
}
.react-chatbot-kit-chat-message-container {
  width: 100%; /* Ensure it takes the full width */
  max-width: 100%; /* Prevent it from exceeding the parent width */
  box-sizing: border-box; /* Include padding and border in the element's width */
  padding: 0; /* Remove any padding */
  margin: 0; /* Remove any margin */
  display: block; /* Ensures the element behaves like a block-level element */
  overflow-y: auto; /* Allows for vertical scrolling if content overflows */
}
.react-chatbot-kit-chat-message-container {
  width: 100% !important; 
  max-width: 100% !important;
}
.react-chatbot-kit-chat-container {
  height: 90vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0; /* Reset padding to ensure full width and height */
}

.react-chatbot-kit-chat-inner-container {
  flex-grow: 1; /* Take up all available space */
  width: 100%; /* Full width */
  margin: 0;
  padding: 0; /* Eliminate any padding */
  display: flex;
  flex-direction: column;
}

.react-chatbot-kit-chat-message-container {
  flex-grow: 1;
  width: 100%; /* Full width */
  padding: 0;
  margin: 0; /* Reset margins */
  overflow-y: auto; /* Allow scrolling */
}
/* Resets any margin or padding that might affect sizing */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent scroll on body directly */
}

/* Ensures the chat container takes full viewport width */
.react-chatbot-kit-chat-container {
  height: 90vh;
  width: 100vw; /* This should theoretically take the full viewport width */
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: white; /* Just to confirm it's visually covering everything */
  z-index: 1000; /* Ensures it's on top of other content */
}

.react-chatbot-kit-chat-bot-message-container {
  display: flex;
  align-items: center; /* Vertically align items in the middle */
  justify-content: flex-start; /* Align items to the left */
}

.react-chatbot-kit-chat-bot-avatar {
  margin-right: 10px; /* Add some space between avatar and message */
}

.react-chatbot-kit-chat-bot-message {
  flex-grow: 1; /* Allow the message to take up the remaining space */
}
.App-logo {
  max-width: 20px;
  margin-right: 10px;
}
.react-chatbot-kit-chat-input-container {
  display: none !important;
}